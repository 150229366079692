import Header from 'components/Header';
import Footer from 'components/Footer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import articles from '../../services/articles';
import { graphql } from 'gatsby';
import Head from './Head';

const ArcticlePage = ({ props }) => {
  const {
    author,
    date,
    detailText,
    subtitle,
    content,
    description,
    id,
    imageName,
    slug,
    tags,
    title,
  } = props.pageContext.page;

  const articlesRelateds = articles
    .filter((article) => article.id !== id)
    .slice(0, 3);

  const img = props.data.photos.edges.find((img) => img.node.base == imageName);
  const imageUrl = getImage(img.node);

  return (
    <>
      <Head props={props} />
      <Header />
      <header className='pt-10'>
        <div className='container'>
          <div className='text-center col-12 col-sm-12 col-lg-10 col-xl-9 mx-auto position-relative z-index-20'>
            <h1 className='display-3 fw-bold mb-3'>{title}</h1>
            <p className='text-muted lead mb-0 text-center'>
              <span className='text-uppercase tracking-wider fw-bolder fs-xs d-flex justify-content-center opacity-50'>
                Criado por:{' '}
                <span className='m-0 ms-1 text-normal'> {author}</span>
              </span>

              <span className='text-uppercase tracking-wider fw-bolder fs-xs d-flex justify-content-center opacity-50'>
                Publicado: <span className='m-0 ms-1 text-normal'> {date}</span>
              </span>
            </p>
          </div>
        </div>
      </header>
      <div className='container position-relative z-index-20 py-7'>
        <div className='row gx-8 align-items-center'>
          <div className='col-0 col-lg-2'></div>
          <div className='col-12 col-lg-8'>
            <span className='fw-bold mb-3 tracking-wider text-uppercase fs-xs text-muted'>
              Tags
            </span>
            <br />
            {tags.map((tag, index) => (
              <span
                key={index}
                className='badge rounded me-2 align-middle'
                style={{
                  color: '#333',
                  background: `#${Math.random().toString(16).substr(-6)}`,
                }}
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className='container position-relative z-index-20'>
        <div className='row gx-8 align-items-center'>
          <div className='col-0 col-lg-2'></div>
          <div className='col-12 col-lg-8'>
            <p className='mb-3 small fw-bolder tracking-wider text-uppercase text-primary'>
              {detailText}
            </p>
            <h2 className='display-5 fw-bold mb-2'>{subtitle}</h2>
            <p className='mb-5'>{description}</p>

            <picture>
              <GatsbyImage
                className='img-fluid rounded shadow-sm mb-6'
                image={imageUrl}
                alt={title}
              />
            </picture>

            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
      {articlesRelateds.length ? (
        <div className='bg-primary py-10 my-6'>
          <div className='container'>
            <h4 className='fs-1 fw-bold mb-6 text-white text-center'>
              Postagens Relacionadas
            </h4>

            <div className='row g-5'>
              {articlesRelateds.map((el) => {
                const img = props.data.photos.edges.find(
                  (img) => img.node.base == el.imageName
                );
                const imageUrl = getImage(img.node);
                return (
                  <article className='col-12 col-sm-6 col-lg-4' key={el.id}>
                    <div className='d-flex h-100 bg-white rounded card overflow-hidden shadow-lg position-relative hover-lift'>
                      <picture>
                        <GatsbyImage
                          className='img-fluid'
                          image={imageUrl}
                          alt={el.title}
                        />
                      </picture>

                      <div className='card-body p-4 p-lg-5'>
                        <p className='card-title fw-medium mb-4'>{el.title}</p>
                        <p className='card-title fw-medium mb-4 '>
                          {el.description}
                        </p>
                        <a
                          href={`/blog/${el.slug}`}
                          className='fw-medium fs-7 text-decoration-none link-cover'
                        >
                          Leia mais →
                        </a>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>

            <a
              href='/'
              className='btn btn-white mx-auto mt-7 d-table fw-medium w-100 w-md-auto'
            >
              Ver todos os posts &rarr;
            </a>
          </div>
        </div>
      ) : (
        ''
      )}
      <Footer />
    </>
  );
};

export const pageQuery = graphql`
  query {
    photos: allFile(
      sort: { fields: base, order: ASC }
      filter: { extension: { regex: "/(jpg)/" } }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              height: 400
              formats: AUTO
              width: 600
            )
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default ArcticlePage;
