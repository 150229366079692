import { Helmet } from 'react-helmet';
import utils from '../../utils';
import { getImage } from 'gatsby-plugin-image';

const Head = ({ props }) => {
  const { description, imageName, title } = props.pageContext.page;

  const img = props.data.photos.edges.find((img) => img.node.base == imageName);
  const imageUrl = getImage(img.node);
  const imgSeo = `https://codeeweb.com.br/blog/${imageUrl.images.fallback.src}`;
  return (
    <>
      <Helmet>
        <link rel='preload' href={imgSeo} as='image' />
      </Helmet>
      {utils.generateMetatags({
        title: title,
        description: description,
        shareImage: imgSeo,
        color: '#2c4bff',
      })}
    </>
  );
};

export default Head;
