import ArcticlePage from '../pages/ArticlePage';
import { graphql } from 'gatsby';

const Article = (props) => {
  return <ArcticlePage props={props} />;
};

export const pageQuery = graphql`
  query {
    photos: allFile(
      sort: { fields: base, order: ASC }
      filter: { extension: { regex: "/(jpg)/" } }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, formats: AUTO)
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

// export { Head } from '../pages/Product/Head'

export default Article;
